<template>
  <main>
    <div class="container about">
      <div class="row text-center training-title">
        <div class="col">
          ¿QUIENES SOMOS?
        </div>
      </div>
      <div class="row about">
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <img
            class="img-fluid"
            src="../assets/img/img/princial-about-us.jpg"
          />
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h2>Centro de Formación</h2>
          <p>
            Somos un Centro de Formación en diferentes Tecnologías en el ámbito
            de Informática y Telecomunicaciones, el cual se presenta como una
            opción de estudio y capacitación para obtener la CERTIFICACION
            INTERNACIONAL de gran demanda para las actuales y nuevas tecnologías
            emergentes, tales como Cisco, Dell, Fortinet, VMWare, Veeam, Proxmox
          </p>
        </div>
      </div>
      <div class="row about flex-row-reverse">
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <img
            class="img-fluid"
            src="../assets/img/img/AboutPrincipalImageWeb.jpg"
          />
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h2>Representantes Internacionales</h2>
          <p>
            Siendo representantes de marcas internacionales como CISCO,
            FORTINET, VMWARE, PROXMOX presentamos su programa de Certificación
            Internacional, que ha sido diseñado para suministrar las
            herramientas y la información necesaria para triunfar en el mercado
            de servicios y equipos para las redes.
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.container.about {
  color: #201b4a;
}
.training-title {
  font-weight: 600;
  font-size: 2rem;
}
.row.about {
  margin: 50px 0px;
  padding: 10px 0px;
  min-height: 280px;
}

</style>
